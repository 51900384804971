const axios = require('axios');
axios.defaults.headers.common['Accept'] = 'application/json'

$(document).on('turbolinks:load', () => {
  const onDashboardPage = $('body').hasClass('home') && $('body').hasClass('index')

  if (onDashboardPage) {
    axios.post('home/statistic')
  }
})
