// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('material-design-icons')
require('bootstrap-material-design')
require('material-dashboard/assets/js/plugins/bootstrap-notify')
require('chartist')
require("@fortawesome/fontawesome-free/js/all")
require('md-date-time-picker/dist/js/mdDateTimePicker')
require('select2/dist/js/select2')
require('cocoon-rails/src/cocoon')
require('./dashboard')
require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

global.$ = jQuery;
global.mdDateTimePicker = mdDateTimePicker;

import md from './material-dashboard'
import mdDateTimePicker from 'md-date-time-picker'
import Pagy from './pagy'
import Chartist from 'chartist'
import { dailyRevenueChart, challengeParticipantsChart, dailyStepsChart } from './dashboard_chart'
import './notification'
import './stylesheets/application'
import './general'

document.addEventListener("turbolinks:load", () => {
    setTimeout(function () {
        md.initDashboardPageCharts();
        dailyRevenueChart()
        challengeParticipantsChart()
        dailyStepsChart()
    }, 500);
    window.md = md;
    window.Chartist = Chartist;
    Pagy.init();
    $('[data-toggle="tooltip"]').tooltip();
    // select2 form
    $('select.select2').select2();
    $('select.select2-tags').select2({
        tags: true,
        formatNoMatches: function() {
            return '';
        }
    });
    $('form').on('cocoon:after-insert', function () {
        $('select.select2').select2()
        $('select.select2-tags').select2({
            tags: true,
            formatNoMatches: function() {
                return '';
            }
        });
    });

})

