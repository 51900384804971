// Adding and removing rows.
  function bindAddNewRow(rowClass, containerId) {
    const newKey  = parseInt(Math.random() * 10000000);
    var cloned    = $(`${ rowClass }:first()`).clone();

    cloned.find('input[name*="[0]"], select[name*="[0]"]').each(function(index, target) {
      const newId   = $(target).attr('id').replace('0', `${ newKey }`);
      const newName = $(target).attr('name').replace('[0]', `[${ newKey }]`);
      $(target).attr('id', newId);
      $(target).attr('name', newName);
    });

    bindRemoveRows(cloned.find('.remove-row'));
    $(containerId).append(cloned);
    $(`${ rowClass }:last`).removeClass('d-none');
    return cloned;
  }

  function bindRemoveRows(elements) {
    elements.click(function() {
      $(this).closest('.removable-row').remove();
    });
  }

// Expose globally.
window.bindAddNewRow  = bindAddNewRow;
window.bindRemoveRows = bindRemoveRows;


// Bootstrap tooltips initialized by - data-toggle="tooltip", will get stuck if they are used on links.
// After clicking on a link, and then navigating back (browser back), the tooltip will stay in place and can only be removed by reloading the page.
$(window).on('popstate', function(event) {
  // Triggers when navigating using browser back/forward button.
  setTimeout(() => {
    const tooltips = $('[class*="bs-tooltip-"]');
    if (tooltips.length > 0) tooltips.remove();
  }, 1000)
});