import consumer from "./consumer"
import CableReady from 'cable_ready'
import { dailyRevenueChart, challengeParticipantsChart, dailyStepsChart } from '../packs/dashboard_chart'

consumer.subscriptions.create("DashboardChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('dashboard channel connected')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations);

      data.operations.morph.map((element) => element.selector).forEach((id) => {
        switch (id) {
          case '#challenges-chart':
            challengeParticipantsChart();
            break;
          case '#payments-chart':
            dailyRevenueChart();
            break;
          case '#steps-chart':
            dailyStepsChart();
            break;
        }
      })
    }
  }
});