import consumer from "./consumer"
import CableReady from 'cable_ready'

consumer.subscriptions.create("StepsViewsChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log('step views channel connected')
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        console.log('received data', data)
        if (data.cableReady) {
            CableReady.perform(data.operations)
        }
    }
});