import 'material-dashboard/assets/js/plugins/bootstrap-notify'

const triggerNotification = (type, message, from, align) => {
    $.notify({
        icon: "add_alert",
        message: message
    }, {
        type: type,
        timer: 3000,
        placement: {
            from: from,
            align: align
        }
    });
}

document.addEventListener('turbolinks:load', () => {
    const message = $('#js-alert').text();

    if (message) {
        triggerNotification('info', message, 'top', 'right')
    }
})