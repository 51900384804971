import Chartist from 'chartist'

const dailyRevenueChart = () => {
  if ($('#dailyRevenueChart').length != 0) {
    const titles = $('#dailyRevenueChart').data('titles')
    const series = $('#dailyRevenueChart').data('series')
    const max = $('#dailyRevenueChart').data('max')
    // console.log('titles', titles)
    // console.log('series', series)
    // console.log('max', max)
    const dataDailyRevenueChart = {
      labels: titles,
      series: [
        series
      ]
    };
    const optionsDailyRevenueChart = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: 0,
      high: max || 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    }

    var dailyRevenueChart = new Chartist.Line('#dailyRevenueChart', dataDailyRevenueChart, optionsDailyRevenueChart);

    md.startAnimationForLineChart(dailyRevenueChart);
  }
}

const challengeParticipantsChart = () => {
  if ($('#challengeParticipantsChart').length != 0) {
    const series = $('#challengeParticipantsChart').data('series')
    const max = $('#challengeParticipantsChart').data('max')
    var challengeParticipantsChart = {
      labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
      series: [
        series
      ]
    };
    var optionsChallengeParticipantsChart = {
      axisX: {
        showGrid: false
      },
      low: 0,
      high: max || 100,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0
      }
    };
    var responsiveOptions = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
    var participantsChart = Chartist.Bar('#challengeParticipantsChart', challengeParticipantsChart, optionsChallengeParticipantsChart, responsiveOptions);

    md.startAnimationForBarChart(participantsChart);
  }
}

const dailyStepsChart = () => {
  if ($('#dailyStepsChart').length != 0) {
    const titles = $('#dailyStepsChart').data('titles')
    const series = $('#dailyStepsChart').data('series')
    const max = $('#dailyStepsChart').data('max')

    const dataDailyStepsChart = {
      labels: titles,
      series: [
        series
      ]
    };

    const optionsDailyStepsChart = {
      axisX: {
        showGrid: false
      },
      low: 0,
      high: max || 100,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0
      }
    }

    var dailyStepsChart = Chartist.Bar('#dailyStepsChart', dataDailyStepsChart, optionsDailyStepsChart);

    // start animation for the Completed Tasks Chart - Line Chart
    md.startAnimationForBarChart(dailyStepsChart);
  }
}


export {
  dailyRevenueChart,
  challengeParticipantsChart,
  dailyStepsChart
}